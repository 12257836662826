export const defaultProposalFromEmails = [
  // Greece
  { countryCode: 'GR', emails: ['info.gr@kramp.com'] },

  // Belgium
  {
    countryCode: 'BE',
    emails: [
      'fg.be@kramp.com',
      'cp.be@kramp.com',
      'mp.be@kramp.com',
      'vp.be@kramp.com',
      'sws.be@kramp.com',
      'el.be@kramp.com',
      'hyd.be@kramp.com',
      'pn.be@kramp.com',
      'tm.be@kramp.com',
    ],
  },

  // Netherlands
  {
    countryCode: 'NL',
    emails: [
      'machinedelen.nl@kramp.com',
      'voertuigdelen.nl@kramp.com',
      'tuinenpark.nl@kramp.com',
      'aandrijftechniek.nl@kramp.com',
      'hydrauliek.nl@kramp.com',
      'winkelenwerkplaats.nl@kramp.com',
      'elektro.nl@kramp.com',
      'pneumatiek.nl@kramp.com',
      'constructie.nl@kramp.com',
    ],
  },

  // Switzerland
  {
    countryCode: 'CH',
    emails: ['info.ch@kramp.com'],
  },

  // Austria
  {
    countryCode: 'AT',
    emails: [
      'info.at@kramp.com',
      'landtechnik.de@kramp.com',
      'baumaschinenteile.de@kramp.com',
      'schlepper_fzteile.de@kramp.com',
      'hydraulik.de@kramp.com',
      'antriebstechnik.de@kramp.com',
      'shop_werkstatt.de@kramp.com',
      'forst_garten.de@kramp.com',
    ],
  },

  // Germany
  {
    countryCode: 'DE',
    emails: [
      'info.de@kramp.com',
      'oem.de@kramp.com',
      'aufarbeitung.de@kramp.com',
      'landtechnik.de@kramp.com',
      'schlepper_fzteile.de@kramp.com',
      'shop_werkstatt.de@kramp.com',
      'hydraulik.de@kramp.com',
      'forst_garten.de@kramp.com',
      'baumaschinenteile.de@kramp.com',
      'antriebstechnik.de@kramp.com',
    ],
  },

  // France
  {
    countryCode: 'FR',
    emails: [
      'info.fr@kramp.com',
      'assistance.fr@kramp.com',
      'justine.richard@kramp.com',
      'marie.clerc@kramp.com',
      'tristan.gaud@kramp.com',
      'henri.caillaud@kramp.com',
      'yves.gaud@kramp.com',
      'piecesmachines.fr@kramp.com',
      'piecestracteurs.fr@kramp.com',
      'motoculture.fr@kramp.com',
      'transmission.fr@kramp.com',
      'hydraulique.fr@kramp.com',
      'atelier.fr@kramp.com',
    ],
  },

  // Ireland
  {
    countryCode: 'IE',
    emails: [
      'customerservice.uk@kramp.com',
      'sales.uk@kramp.com',
      'garden.uk@kramp.com',
      'hydtrans.uk@kramp.com',
      'shopworkshop.uk@kramp.com',
      'tractormachinery.uk@kramp.com',
    ],
  },

  // United Kingdom
  {
    countryCode: 'GB',
    emails: [
      'garden.uk@kramp.com',
      'hydtrans.uk@kramp.com',
      'shopworkshop.uk@kramp.com',
      'tractormachinery.uk@kramp.com',
    ],
  },

  // Sweden
  {
    countryCode: 'SE',
    emails: [
      'kramp.se@kramp.com',
      'redskap.se@kramp.com ',
      'hydraulic.se@kramp.com',
      'transmission.se@kramp.com',
      'pst.se@kramp.com',
      'universal.se@kramp.com',
      'verktyg.se@kramp.com',
      'stangsel.se@kramp.com',
      'traktorvagn.se@kramp.com',
    ],
  },

  // Denmark
  {
    countryCode: 'DK',
    emails: [
      'kramp.dk@kramp.com',
      'traktor.dk@kramp.com',
      'landbrug.dk@kramp.com',
      'parkogskov.dk@kramp.com',
      'transmission.dk@kramp.com',
      'hydraulik.dk@kramp.com',
      'universal.dk@kramp.com',
    ],
  },

  // Norway
  {
    countryCode: 'NO',
    emails: [
      'kramp.no@kramp.com',
      'produkt1.no@kramp.com',
      'produkt2.no@kramp.com',
      'produkt3.no@kramp.com',
      'produkt4.no@kramp.com',
    ],
  },

  // Poland
  {
    countryCode: 'PL',
    emails: [
      'info.pl@kramp.com',
      'czesci_maszyny.pl@kramp.com',
      'transmisja.pl@kramp.com',
      'hydraulika.pl@kramp.com',
      'czesci_traktor.pl@kramp.com',
      'sklep_warsztat_zootechnika.pl@kramp.com',
      'las_ogrod.pl@kramp.com',
      'construction.pl@kramp.com',
    ],
  },

  // Finland
  {
    countryCode: 'FI',
    emails: [
      'asiakaspalvelu.fi@kramp.com',
      'tuote1@kramp.com',
      'tuote2@kramp.com',
      'tuote3@kramp.com',
      'tuote4@kramp.com',
    ],
  },

  // Estonia
  {
    countryCode: 'EE',
    emails: ['support.lt@kramp.com'],
  },

  // Latvia
  {
    countryCode: 'LV',
    emails: ['support.lt@kramp.com'],
  },

  // Lithuania
  {
    countryCode: 'LT',
    emails: ['support.lt@kramp.com'],
  },

  // Bulgaria
  {
    countryCode: 'BG',
    emails: ['info.bg@kramp.com'],
  },

  // Romania
  {
    countryCode: 'RO',
    emails: ['info.ro@kramp.com', 'tehnic.ro@kramp.com'],
  },

  // Hungary
  {
    countryCode: 'HU',
    emails: ['info.hu@kramp.com', 'muszaki.hu@kramp.com'],
  },

  // Czech Republic
  {
    countryCode: 'CZ',
    emails: ['info.cz@kramp.com', 'podpora.cz@kramp.com'],
  },

  // Slovakia
  {
    countryCode: 'SK',
    emails: ['info.sk@kramp.com ', 'podpora.sk@kramp.com'],
  },

  // Slovenia
  {
    countryCode: 'SI',
    emails: ['info.si@kramp.com'],
  },

  // Croatia
  {
    countryCode: 'HR',
    emails: ['info.hr@kramp.com'],
  },

  // Spain
  {
    countryCode: 'ES',
    emails: ['ventas.es@kramp.com', 'asistencia.tecnica@kramp.com'],
  },

  // Italy
  {
    countryCode: 'IT',
    emails: [
      'sales.it@kramp.com',
      'trattori.it@kramp.com',
      'giardinaggio.it@kramp.com',
      'negozio.it@kramp.com',
      'attrezzature.it@kramp.com',
      'oleodinamica.it@kramp.com',
    ],
  },

  // Australia
  { countryCode: 'AU', emails: ['export@kramp.com'] },

  // Bosnia and Herzegovina
  { countryCode: 'BA', emails: ['export@kramp.com'] },

  // Canada
  { countryCode: 'CA', emails: ['export@kramp.com'] },

  // Chile
  { countryCode: 'CL', emails: ['export@kramp.com'] },

  // Cyprus
  { countryCode: 'CY', emails: ['export@kramp.com'] },

  // Iceland
  { countryCode: 'IS', emails: ['export@kramp.com'] },

  // Japan
  { countryCode: 'JP', emails: ['export@kramp.com'] },

  // Kosovo
  { countryCode: 'XK', emails: ['export@kramp.com'] },

  // Moldova
  { countryCode: 'MD', emails: ['export@kramp.com'] },

  // New Zealand
  { countryCode: 'NZ', emails: ['export@kramp.com'] },

  // North Macedonia
  { countryCode: 'MK', emails: ['export@kramp.com'] },

  // Serbia
  { countryCode: 'RS', emails: ['export@kramp.com'] },

  // Turkey
  { countryCode: 'TR', emails: ['export@kramp.com'] },

  // United Arab Emirates
  { countryCode: 'AE', emails: ['export@kramp.com'] },

  // United States
  { countryCode: 'US', emails: ['export@kramp.com'] },
];
